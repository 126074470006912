import { notification } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import ERROR from '../../../assets/images/error.png'
import SUCCESS from '../../../assets/images/success.png'
import societeApi from '../../../http/societeApi'
import { Societe, SocieteEditPayload, SocieteResponse } from '../../../models'
import { CommonError } from '../../../models/common/error'
import { SocieteGestionStateInterface } from './societeDetail/SocieteGestion'
import { SocieteMainSectionStateInterface } from './societeDetail/SocieteMainSection'
import Define from '../../../constants/define'

const useSocieteHook = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const societeId = searchParams.get('societe-id') || ''
  const [errorMainSection, setErrorMainSection] = useState<CommonError[]>([])
  const [isValidMainSection, setIsValidMainSection] = useState<boolean>(false)
  const [mainSectionData, setMainSectionData] =
    useState<SocieteMainSectionStateInterface>()
  const [societeData, setSocieteData] = useState<SocieteResponse>()
  const [gestionData, setGestionData] = useState<SocieteGestionStateInterface>()
  const isValidAll = isValidMainSection
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openPopupDelete, setOpenPopupDelete] = useState<boolean>(false)

  useEffect(() => {
    if (!societeId) return
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = (await societeApi.getSocieteById({ id: societeId }))
          .data.entry
        setMainSectionData({
          id: response.id,
          nom: response.nom,
          code: response.code,
          warehouse_ids: response.warehouse_company?.map((el) => el.id),
          warehouse_company: response.warehouse_company,
        })
        setGestionData({
          isPicking: response.priority_picking,
        })
        setSocieteData(response)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        showErrorMessage(t('popupErrorGeneral'))
        onCancelModal()
      }
    }
    fetchData()
  }, [societeId])

  useEffect(() => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    if (mainSectionData?.code) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(
            item.name === 'code' &&
            (item.errorMessage === 'Code déjà existant' ||
              item.errorMessage === ' ')
          )
      )
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(item.name === 'code' && item.errorMessage === 'Code déjà existant')
      )
    }
    if (mainSectionData?.nom) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSectionData])

  const onCancelModal = () => {
    navigate(
      `/configurations/societe?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const onChangeDataMainSection = (data: SocieteMainSectionStateInterface) => {
    setMainSectionData(data)
  }

  const onCheckValidMainSection = (isValid: boolean) => {
    setIsValidMainSection(isValid)
  }

  const onChangeDataGestion = (data: SocieteGestionStateInterface) => {
    setGestionData(data)
  }

  const showErrorMessage = (message: string) => {
    notification.open({
      className: 'noti noti-error',
      message: (
        <div className="flex items-center">
          <img src={ERROR} alt="error" width={50} className="mr-2.5" />

          {message}
        </div>
      ),
      placement: 'topRight',
      closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
      duration: 3,
    })
  }

  const onSubmit = async () => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    if (!mainSectionData?.code) {
      errorMainSectionClone.push({
        name: 'code',
        errorMessage: '',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'code' && item.errorMessage !== ' '
      )
    }
    if (!mainSectionData?.nom) {
      errorMainSectionClone.push({
        name: 'nom',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)

    let dataCreate: Societe = {}
    let dataEdit: SocieteEditPayload = {
      company: {},
    }
    if (isValidAll) {
      setIsLoading(true)
      if (!societeId) {
        dataCreate.code = mainSectionData?.code?.trim()
        dataCreate.nom = mainSectionData?.nom?.trim()
        dataCreate.warehouse_ids = mainSectionData?.warehouse_ids
        dataCreate.priority_picking = gestionData?.isPicking || undefined
        await createSociete(dataCreate)
      } else {
        dataEdit.company.id = mainSectionData?.id
        dataEdit.company.code = mainSectionData?.code?.trim()
        dataEdit.company.nom = mainSectionData?.nom?.trim()
        dataEdit.company.priority_picking = gestionData?.isPicking || undefined
        dataEdit.company.warehouse_company = mainSectionData?.warehouse_company
        dataEdit.warehouse_company_id_delete =
          mainSectionData?.warehouse_company_id_delete

        await editSociete(dataEdit)
      }
    }
  }

  const editSociete = async (data: SocieteEditPayload) => {
    const prevSociete = localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
    const curSociete = `${data.company.code}_${data.company.nom}`
    try {
      await societeApi.editSociete(data)
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            {t('editSuccess')}
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      if (societeData?.code_nom === prevSociete) {
        localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, curSociete)
      }
      setIsLoading(false)
      onCancelModal()
    } catch (e: any) {
      if (
        e.response.status === 400 &&
        e.response?.data?.error?.includes('code have already existed')
      ) {
        const errorMainSectionClone = cloneDeep(errorMainSection)
        errorMainSectionClone.push({
          name: 'code',
          errorMessage: 'Code déjà existant',
        })
        setErrorMainSection(errorMainSectionClone)
      } else if (
        e.response?.data?.error?.includes(
          'can not delete warehouse_companies already to use'
        )
      ) {
        showErrorMessage(t('canNotDelete'))
      } else showErrorMessage(t('popupErrorGeneral'))
      setIsLoading(false)
    }
  }

  const createSociete = async (data: Societe) => {
    try {
      await societeApi.createSociete(data)
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            {`${t('success')}: ${t('company')} ${t('record')}`}
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsLoading(false)
      onCancelModal()
    } catch (e: any) {
      if (e.response?.data?.error?.includes('code have already existed')) {
        const errorMainSectionClone = cloneDeep(errorMainSection)
        errorMainSectionClone.push({
          name: 'code',
          errorMessage: 'Code déjà existant',
        })
        setErrorMainSection(errorMainSectionClone)
      } else showErrorMessage(t('popupErrorGeneral'))
      setIsLoading(false)
    }
  }

  const deleteSingleSociete = async () => {
    setIsLoading(true)
    setOpenPopupDelete(false)
    try {
      await societeApi.deleteSocieteById({ id: societeId })
      onCancelModal()
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      showErrorMessage(t('popupErrorGeneral'))
    }
  }

  return {
    errorMainSection,
    mainSectionData,
    isValidMainSection,
    gestionData,
    isLoading,
    societeData,
    onCancelModal,
    onChangeDataMainSection,
    onCheckValidMainSection,
    onChangeDataGestion,
    onSubmit,
    deleteSingleSociete,
    openPopupDelete,
    setOpenPopupDelete,
  }
}

export default useSocieteHook
